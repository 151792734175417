var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(_vm._s(_vm.$t('other.myMessage')))]), _vm._m(1), _c('div', {
    staticClass: "sub_title"
  }, [_vm._v(_vm._s(_vm.$t('title.platformNotice')))])]), _c('div', {
    staticClass: "all_message"
  }, _vm._l(_vm.messageList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "message_item",
      on: {
        "click": function ($event) {
          return _vm.checkDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "message_item_top"
    }, [_c('div', {
      staticClass: "message_item_left"
    }, [_c('img', {
      attrs: {
        "src": item.thumb
      }
    })]), _c('div', {
      staticClass: "message_item_right"
    }, [_c('div', {
      staticClass: "message_name"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "message_time"
    }, [_vm._v(_vm._s(item.create_time))])])]), _c('div', {
      staticClass: "message_item_bottom"
    }, [_vm._v(" " + _vm._s(item.desc) + " ")])]);
  }), 0), _c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.dialogVisible,
      "width": "38%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "title_all"
  }, [_c('div', {
    staticClass: "title_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.detail.title))])])]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "body",
    domProps: {
      "innerHTML": _vm._s(_vm.detail.contents)
    }
  })])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "front_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/front.png")
    }
  })]);
}];
export { render, staticRenderFns };