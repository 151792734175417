export default {
  data() {
    return {
      messageList: [],
      pageNo: 1,
      pageSize: 10000,
      detail: '',
      dialogVisible: false
    };
  },
  mounted() {
    this.getPlatformMsg();
  },
  methods: {
    // 获取分类信息
    getPlatformMsg() {
      let data = {
        page: this.pageNo,
        pageSize: this.pageSize,
        msgtype: 1
      };
      this.$http.getMsgList(data).then(res => {
        console.log(res);
        if (res.code == 1) {
          this.messageList = res.data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      this.$router.back();
    },
    checkDetail(e) {
      this.dialogVisible = true;
      this.$http.getMsgDetail({
        sys_msg_id: e.id
      }).then(res => {
        if (res.code == 1) {
          this.detail = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    }
  }
};